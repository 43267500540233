import React, { useMemo } from 'react';
import InventoryTable from 'components/Dashboard/InventoryTable/InventoryTable';
import {
  WORKFLOW_STATUS,
  classNamesFromStatus,
} from 'components/VirtualAtelier/StyleSummary/util';
import { getListingColor } from 'utils/getListingColor';

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'STYLE NAME',
  },
  {
    header: 'STYLE NO',
  },
  {
    header: 'CATEGORIES',
  },
  {
    header: 'Line',
  },
  {
    header: 'Sizing',
  },
  {
    header: 'colour',
  },
  {
    header: 'Status',
  },
];

const AtelierInventoryTable = ({ listings, styles, editTileOptions, ...props }) => {
  const cellData = useMemo(
    () =>
      listings?.map(listing => {
        const status =
          styles?.find(s => +s.key === +listing.style)?.status ??
          WORKFLOW_STATUS.IN_PROGRESS.value;

        return {
          key: listing.key,
          col: [
            {
              imageUrl: listing.imageUrl,
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.style_number || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.category_names.filter(l => !!l).join(', ') || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.line_name || '',
              linkTo: listing.linkTo,
            },
            {
              cell: listing.size || '',
              linkTo: listing.linkTo,
            },
            {
              cell: getListingColor(listing),
              linkTo: listing.linkTo,
            },
            {
              cell: <button className={classNamesFromStatus(status)}>{status}</button>,
              linkTo: listing.linkTo,
            },
          ],
          data: listing,
        };
      }),
    [listings, styles]
  );

  return (
    <InventoryTable
      headerGroups={headerGroups}
      cellData={cellData}
      popUpOptions={editTileOptions}
      {...props}
    />
  );
};

export default AtelierInventoryTable;

import classNames from 'classnames';

export const WORKFLOW_STATUS = {
  UPLOADED: {
    value: 'Asset Uploaded',
    color: { bg: 'color-error-3-bg' },
    job_type: 'designer',
  },
  IN_PROGRESS: {
    value: 'In Progress',
    color: { bg: 'color-info-3-bg' },
    job_type: 'vsm',
  },
  READY: {
    value: 'Ready for Review',
    color: { bg: 'color-warning-3-bg' },
    job_type: 'vsm',
  },
  DECLINED: {
    value: 'Declined',
    color: { bg: 'color-declined-bg' },
    job_type: 'designer',
  },
  APPROVED: {
    value: 'Approved',
    color: { bg: 'color-success-3-bg' },
    job_type: 'designer',
  },
  FINAL: {
    value: 'Final',
    color: { bg: 'color-success-2-bg' },
  },
};

export const classNamesFromStatus = status => {
  const names = Object.values(WORKFLOW_STATUS).reduce((conditions, s) => {
    conditions[s.color.bg] = status === s.value;
    return conditions;
  }, {});

  return classNames('button-badge', names);
};

export type Dictionary<T = any> = { [key: string]: T };

export const ASSET_TYPES: Dictionary = {
  image: {
    type: 'image',
    extensions: ['jpeg', 'webm', 'png', 'jpg'],
  },
  video: { type: 'video', extensions: ['mp4', 'mov'] },
  spreadsheet: {
    type: 'spreadsheet',
    extensions: ['xls', 'csv', 'xlsx', 'gsheet', 'numbers'],
  },
  model: {
    type: 'model',
    extensions: ['zpac', 'zfab', 'sbs', 'sbsar', 'xtex', 'u3m', 'gltf', 'glb'],
  },
  zprj: { type: 'zprj', extensions: ['zprj'] },
  svg: { type: 'svg', extensions: ['svg'] },
  gif: { type: 'gif', extensions: ['gif'] },
  pdf: { type: 'pdf', extensions: ['pdf'] },
  obj: { type: 'obj', extensions: ['obj'] },
  ai: { type: 'ai', extensions: ['ai'] },
  psd: { type: 'psd', extensions: ['psd'] },
  document: { type: 'doc', extensions: ['doc', 'docx'] },
  zip: { type: 'file', extensions: ['zip', 'tar'] },
  pattern: { type: 'pattern', extensions: ['dxf'] },
  fbx: { type: 'fbx', extensions: ['fbx'] },
  grading: { type: 'grading', extensions: ['rul'] },
  presentation: {
    type: 'presentation',
    extensions: ['ppt', 'pptx', 'pptm'],
  },
};

export const findInFileTypeSet = (set, file) => {
  return set.find(type => {
    return (
      file.type.toLowerCase().indexOf(type) >= 0 ||
      file.name.toLowerCase().indexOf(type) >= 0
    );
  });
};
export const getAssetTypeFromFileType = file => {
  return Object.values(ASSET_TYPES).find(({ extensions }) => {
    return findInFileTypeSet(extensions, file);
  });
};

export const extToAssetType = ext => {
  if (!ext) return null;
  const [type, metadata] = Object.entries(ASSET_TYPES).find(([type, metadata]) => {
    return metadata.extensions.includes(ext);
  });
  return metadata.type;
};

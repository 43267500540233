/* eslint-disable no-constant-condition */
import React, { useState } from 'react';
import './UploadModal.scss';
import { Asset } from 'components';
import { AssetMetadataProps } from 'components/Asset';

import CloseIcon from 'assets/icons/close.svg';
import CloseLightIconGray from 'assets/icons/close-light-gray.svg';
import Loader from 'components/Loader/Loader';
import classNames from 'classnames';

export interface IUploadModal {
  itemsAdded: boolean;
  modalOpen: boolean;
  setModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  onDoneHandler?: (event: React.SyntheticEvent) => void;
  uploadedMedia?: any;
  setUploadedMedia?: React.Dispatch<React.SetStateAction<any[]>>;
}

const UploadModal: React.FC<IUploadModal> = props => {
  const {
    itemsAdded,
    onDoneHandler,
    modalOpen,
    setModalOpen,
    setUploadedMedia,
    uploadedMedia,
  } = props;
  const [save, setSave] = useState(false);

  const saveChanges = event => {
    setSave(true);
    onDoneHandler(event);
  };

  const RemoveImage = targetItem => {
    setUploadedMedia(uploadedMedia.filter(item => item.name !== targetItem.name));
  };
  return (
    <div className='upload-modal'>
      {modalOpen ? (
        <div className='modal modal--regular'>
          <div className='modal__content'>
            <div className='modal__header'>
              <h2 className='modal__title'>Upload Detail</h2>
              <img src={CloseIcon} alt='Close' onClick={() => setModalOpen(false)} />
            </div>
            <div className='modal__inner'>
              {itemsAdded ? (
                <div
                  className={classNames('upload-modal__items-added', {
                    'upload-modal__items-added__loading': !uploadedMedia?.length,
                  })}
                >
                  {uploadedMedia?.length ? (
                    uploadedMedia.map(media => {
                      const { key, assets, local, name } = media;
                      if (assets?.image?.progress === 0 && local) {
                        const [imageAsset] = Object.entries(assets);
                        const tooltipTxt = JSON.stringify(Object.values(media?.errors));
                        return (
                          <div
                            style={{ display: 'block' }}
                            className='upload-modal__item-added'
                          >
                            {Object.keys(media?.errors).length > 0 && (
                              <div className='upload-modal__error' key={key}>
                                <img
                                  src={CloseLightIconGray}
                                  alt={JSON.stringify(Object.values(media.errors))}
                                  onClick={() => RemoveImage(local)}
                                />
                                <span>
                                  <span className='upload-modal__error_text'>
                                    {Object.keys(media?.errors).includes('filename')
                                      ? `Filename error`
                                      : `Error`}
                                  </span>
                                  {tooltipTxt && (
                                    <div className='tooltip'>
                                      <span className='tooltip__txt'>{tooltipTxt}</span>
                                    </div>
                                  )}
                                </span>
                              </div>
                            )}
                            <div className='upload-modal__thumbnail'>
                              <Asset
                                asset={imageAsset}
                                imageAsFileSeed={local}
                                saveImages={save}
                                key={key}
                                customMetadata={{
                                  model: 'Media',
                                  model_id: key,
                                }}
                              >
                                <img
                                  key={key}
                                  src={URL.createObjectURL(local)}
                                  alt={name}
                                />
                              </Asset>
                            </div>
                          </div>
                        );
                      }
                      if (local && Object.entries(assets).length > 0) {
                        const [imageAsset] = Object.entries(assets);
                        return (
                          <div className='upload-modal__item-added'>
                            <Asset
                              asset={imageAsset}
                              imageAsFileSeed={local}
                              saveImages={save}
                              key={key}
                            >
                              <img src={URL.createObjectURL(local)} alt={name} />
                            </Asset>
                          </div>
                        );
                      }
                      return (
                        <div className='upload-modal__item-added' key={key}>
                          <img src={local} alt={name} />
                        </div>
                      );
                    })
                  ) : (
                    <div className='modal-upload-details__details'>
                      <Loader active={true} relative={true}></Loader>
                    </div>
                  )}
                </div>
              ) : (
                <div className='upload-modal__drag-area'>
                  <div className='upload-modal__drag-txt'>
                    Drag your media files here to upload
                  </div>
                  <div className='upload-modal__file-type'>
                    PNG, JPG, Giff, xxxx ( video file type )
                  </div>
                  <button className='button-small color-white-bd-bg'>Select Files</button>
                </div>
              )}
            </div>
            <div className='modal__footer'>
              <button
                className='button-transparent-black button-smaller'
                onClick={() => setModalOpen(false)}
              >
                Cancel
              </button>
              <button onClick={saveChanges} className='button-primary'>
                Save
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default UploadModal;

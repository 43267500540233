import React, { useContext, useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { useLocation } from '@reach/router';
import { UserContext } from 'providers/contexts';
import { mmAPI, putStyleModel, postModel, updateReferencePositions } from 'services/Api';
import StyleSummary from './StyleSummary';
import StyleSampleReview from './StyleSampleReview';
import { UploadContext } from 'providers/UploadProvider';
import { getImageAspectRatio } from 'utils/getImageAspectRatio';
import { getAssetTypeFromFileType } from 'constants/assets';
import { NotificationsContext } from 'providers/NotificationsProvider';
import LogRocket from 'logrocket';
import { FOLDER } from 'components/Dashboard/AssetLibrary/util';
import { WORKFLOW_STATUS } from 'components/VirtualAtelier/StyleSummary/util';

const AtelierSummaryContainer = props => {
  const { brand_model_id, style_model_id } = props;
  const { user } = useContext(UserContext);
  const { idToken } = user;

  const { setDisplayToast } = useContext(NotificationsContext);

  const styleMediaFetchIntervalRef = React.useRef(0);
  const [styleMediaFetchIntervalCount, setStyleMediaFetchIntervalCount] = useState(0);

  const location = useLocation();
  useEffect(() => {
    if (location?.pathname?.indexOf('/samples') > 0) {
      styleMediaFetchIntervalRef.current = 0;
    }
  }, [location?.pathname]);

  const { data: media } = useSWR(
    [`/api/media/query/style/`, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  const { data: references, mutate: mutateReferences } = useSWR(
    [`/api/reference/query/style/`, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
      refreshInterval: styleMediaFetchIntervalRef.current,
      onSuccess: () => {
        setStyleMediaFetchIntervalCount(prev => {
          console.log('prev', prev);
          prev = prev + 1;
          console.log('prevplus', prev);
          console.log('fetchInterval', styleMediaFetchIntervalRef.current);
          return prev;
        });
      },
    }
  );

  const { data: referencesArchive, mutate: mutateReferencesArchive } = useSWR(
    [`/api/reference/query/style_archive/`, idToken, style_model_id],
    (url, idToken, style) => {
      return mmAPI(url, idToken, { style });
    },
    {
      suspense: true,
    }
  );

  const { data: style } = useSWR(
    [`/api/style/model/${style_model_id}`, idToken],
    (url, idToken) => {
      return mmAPI(url, idToken);
    },
    {
      suspense: true,
    }
  );

  const manageRefreshInterval = React.useCallback(
    styleMediaFetchIntervalCountVal => {
      console.log('eval FetchInterval', styleMediaFetchIntervalRef.current);
      if (styleMediaFetchIntervalRef.current === 300) {
        if (media.filter(m => m).every(m => m?.src)) {
          console.log('setBrandMediaFetchInterval reset');
          console.log(
            'media',
            media.map(m => [m?.name, m?.src, m?.progress])
          );
          styleMediaFetchIntervalRef.current = 0;
          setStyleMediaFetchIntervalCount(0);
          console.log(
            'reset setStyleMediaFetchIntervalCount with assetUrls, stop fetch after count',
            styleMediaFetchIntervalCountVal
          );
        } else {
          if (styleMediaFetchIntervalCountVal >= 9) {
            console.log('reset after 9 messageFetchIntervalCount');
            styleMediaFetchIntervalRef.current = 0;
            setStyleMediaFetchIntervalCount(0);
          }
        }
        console.log(
          'setStyleMediaFetchIntervalCount one plus',
          styleMediaFetchIntervalCountVal
        );
      }

      if (styleMediaFetchIntervalCountVal >= 9) {
        console.log('reset after 9 messageFetchIntervalCount');
        styleMediaFetchIntervalRef.current = 0;
        setStyleMediaFetchIntervalCount(0);
      }
    },
    [media]
  );

  useEffect(() => {
    console.log('======= effect manageRefreshInterval');
    manageRefreshInterval(styleMediaFetchIntervalCount);
  }, [styleMediaFetchIntervalCount, manageRefreshInterval]);

  const [styleInfo, setStyleInfo] = useState(null);

  useEffect(() => {
    const styleEffect = styleData => {
      if (!styleData) return;
      const categories = styleData.listing?.category_names
        ? styleData.listing?.category_names?.map((category, i) => {
            return {
              title: style.listing[`category_${i + 1}_label`] || `Category ${i + 1}`,
              content: category,
            };
          })
        : styleData?.category_names.map((c, i) => ({
            title: `category ${i + 1}`,
            content: c,
          }));
      const styleInfoValues = [
        ...categories,
        {
          title: 'colours',
          content:
            styleData?.listing?.color?.name ||
            styleData?.listing?.color?.hexCode ||
            styleData?.listing?.color?.hexCode ||
            styleData?.listing?.color?.pantone ||
            typeof styleData.listing?.color === 'object'
              ? JSON.stringify(styleData?.listing?.color)
              : styleData.listing?.color,
        },
        {
          title: 'look no',
          content: styleData?.listing?.look_number,
        },
        {
          title: 'sizing',
          content: styleData?.listing?.size,
        },
        {
          title: 'material',
          content: styleData?.listing?.materials,
        },
      ];
      setStyleInfo(styleInfoValues);
      setProgressSpecs(prev => ({
        ...prev,
        status_materials: {
          ...prev.status_materials,
          completed: ['completed', 'true', 'inprogress'].includes(
            styleData.status_materials
          ),
        },
        status_patterns: {
          ...prev.status_patterns,
          completed: ['completed', 'true', 'inprogress'].includes(
            styleData.status_patterns
          ),
        },
        status_techpack: {
          ...prev.status_techpack,
          completed: ['completed', 'true', 'inprogress'].includes(
            styleData.status_techpack
          ),
        },
        status_graphics: {
          ...prev.status_graphics,
          completed: ['completed', 'true', 'inprogress'].includes(
            styleData.status_graphics
          ),
        },
        status_references: {
          ...prev.status_references,
          completed: ['completed', 'true', 'inprogress'].includes(
            styleData.status_references
          ),
        },
        status_final: {
          ...prev.status_final,
          completed: ['completed', 'true', 'inprogress'].includes(styleData.status_final),
        },
      }));
    };
    styleEffect(style);
  }, [style]);

  const title = 'Virtual Atelier';
  const tooltipText = 'At vero eos et accusamus et iusto odio dignissimos';
  const tooltipTitle = 'Virtual sample maker';

  const [progressSpecs, setProgressSpecs] = useState({
    status_techpack: {
      title: 'TECH PACK & SPECS',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/techpack`,
    },
    status_patterns: {
      title: 'patterns',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/patterns`,
    },
    status_materials: {
      title: 'materials',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/materials`,
    },
    status_graphics: {
      title: 'graphics & prints',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/graphics`,
    },
    status_references: {
      title: 'additional references',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/references`,
    },
    status_final: {
      title: 'final files',
      completed: false,
      link: `/brand/${brand_model_id}/style/${style_model_id}/final`,
    },
  });

  const onSetStatus = async status => {
    const response = await putStyleModel(idToken, style_model_id, {
      brand: brand_model_id,
      listing: parseInt(style?.listing?.key),
      action: 69,
      ...status,
    });
    if (response?.data?.data) {
      mutate([`/api/style/model/${style_model_id}`, idToken]);
    }
  };

  const { firebaseStorageUrl } = useContext(UploadContext);

  const referenceModelGalleryTransform = async referenceModelVal => {
    try {
      const typeSplit = referenceModelVal.notes?.split('/');
      const nameSplit = referenceModelVal.name?.split('.');

      const referenceTransformed = {
        ...referenceModelVal,
        name: referenceModelVal.name,
        created: referenceModelVal?.created,
        asset: [referenceModelVal.type, referenceModelVal.assets[referenceModelVal.type]],
        brand_model_id: brand_model_id,
        style_model_id: style.key,
        reference_model_id: referenceModelVal.key,
        ext: referenceModelVal.assets[referenceModelVal.type].ext,
      };
      if (referenceModelVal.type === FOLDER) {
        referenceTransformed.progress = 1;
      } else {
        const { assets } = referenceModelVal;

        const assetUrl =
          assets?.[referenceModelVal.type]?.progress > 0
            ? await firebaseStorageUrl(assets?.[referenceModelVal.type]?.path)
            : null;

        referenceTransformed.src = referenceTransformed.assetUrl = assetUrl;
        referenceTransformed.aspectRatio =
          referenceModelVal.type === 'image' ? await getImageAspectRatio(assetUrl) : null;

        referenceTransformed.type =
          typeSplit?.length > 1
            ? typeSplit[typeSplit.length - 1]
            : nameSplit.length > 1
            ? nameSplit[nameSplit.length - 1]
            : 'file';
        referenceTransformed.progress =
          referenceModelVal.assets?.[referenceModelVal.type]?.progress;
      }
      return Promise.resolve(referenceTransformed);
    } catch (error) {
      console.log('error', error);
      LogRocket.captureException(error);
      return Promise.reject(error);
    }
  };

  const [sampleSlideshow, setSampleSlideshow] = useState([]);
  const [sampleSlideshowUploading, setSampleSlideshowUploading] = useState([]);

  useEffect(() => {
    const referencesEffect = async referencesVal => {
      console.log('referencesVal', referencesVal);
      const getMedia = mediaModels => {
        const mediaModelsFiltered = mediaModels.filter(
          m => m.assets?.[m.type]?.progress > 0 || m.type === FOLDER
        );
        console.log('mediaModelsFiltered', mediaModelsFiltered);
        return Promise.all(
          mediaModelsFiltered.map(async m => {
            const virtualSampleReferenceSlide = await referenceModelGalleryTransform(m);
            return virtualSampleReferenceSlide;
          })
        );
      };

      const mediaFiltered = referencesVal.filter(m => m.tags.includes('vsm'));
      const mediaResolved: any = await getMedia(mediaFiltered);
      console.log('mediaResolved', mediaResolved);
      setSampleSlideshowUploading([]);
      setSampleSlideshow(
        [...mediaResolved].sort((a, b) => {
          if (b?.created && a?.created) return b?.created - a?.created;
          if (!b?.created) return -1;
          if (!a?.created) return 1;
          return 0;
        })
      );
    };
    referencesEffect(references);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [references]);

  const [archivedSampleSlideshow, setArchivedSampleSlideshow] = useState([]); // if (mediaFiltered.length) {

  useEffect(() => {
    const referencesEffect = async referencesVal => {
      if (!referencesVal.length) setArchivedSampleSlideshow([]);

      const getMedia = mediaModels => {
        const mediaModelsFiltered = mediaModels.filter(
          m => m.assets?.[m.type]?.progress > 0 || m.type === FOLDER
        );
        return Promise.all(
          mediaModelsFiltered.map(async m => {
            const virtualSampleReferenceSlide = await referenceModelGalleryTransform(m);
            return virtualSampleReferenceSlide;
          })
        );
      };

      const mediaFiltered = referencesVal.filter(m => m.tags.includes('vsm'));
      const mediaResolved: any = await getMedia(mediaFiltered);
      setArchivedSampleSlideshow(
        [...mediaResolved].sort((a, b) => {
          if (b?.created && a?.created) return b?.created - a?.created;
          if (!b?.created) return -1;
          if (!a?.created) return 1;
          return 0;
        })
      );
    };
    referencesEffect(referencesArchive);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referencesArchive]);

  const sampleGalleryTransform = async (
    file,
    path = [],
    annotatedImagePosition = null
  ) => {
    const fileObjectUrl = URL.createObjectURL(file);
    const { type } = getAssetTypeFromFileType(file) || {};
    if (!type) {
      setDisplayToast({
        type: 'error',
        persist: false,
        message: `${file.name} is not a supported file type.`,
      });
      return null;
    }
    const typeSplit = type.split('/');
    const nameSplit = file.name.split('.');

    const response = await postModel(idToken, 'reference', {
      type,
      brand: brand_model_id,
      model: 'Style',
      model_id: style.key,
      name: file.name,
      notes: file.type || type,
      tags: 'vsm,sample',
      position: annotatedImagePosition || 0,
      path: path.length > 0 ? path.join(',') : null,
      style: style_model_id,
      location: window.location.href,
    });
    const styleReferenceData = response?.data?.data;
    const dateNow = Date.now();
    const sampleTransform = {
      brand_model_id: brand_model_id,
      style_model_id: style.key,
      reference_model_id: styleReferenceData?.key,
      customMetadata: {
        model: 'Reference',
        model_id: styleReferenceData?.key,
      },
      created: dateNow,
      key: dateNow,
      name: file.name,
      src: fileObjectUrl,
      type:
        typeSplit.length > 1
          ? typeSplit[1]
          : nameSplit.length > 1
          ? nameSplit[nameSplit.length - 1]
          : 'file',
      save: true,
      asset: [type, styleReferenceData?.assets?.[type]],
      progress: -1,
      imageAsFileSeed: file,
      onUploadHandler: () => {
        console.log('onUploadHandler');
        styleMediaFetchIntervalRef.current = 200;
        mutateReferences();
        mutateReferencesArchive();
      },
    };
    console.log('customMetadata sampleTransform', sampleTransform);
    return Promise.resolve(sampleTransform);
  };

  const onDropHandler = async (files, path = [], annotatedImagePosition = null) => {
    const sampleTransforms = await Promise.all(
      Array.from(files).map(file =>
        sampleGalleryTransform(file, path, annotatedImagePosition)
      )
    );
    setSampleSlideshowUploading(sampleTransforms);
    setSampleSlideshow(prev =>
      [...prev, ...sampleTransforms].sort((a, b) => {
        if (b?.created && a?.created) return b?.created - a?.created;
        if (!b?.created) return -1;
        if (!a?.created) return 1;
        return 0;
      })
    );
  };

  const updatePositionsHandler = async param => {
    await updateReferencePositions(idToken, param);
    mutateReferences();
  };

  return (
    <>
      {location.pathname.indexOf('/samples') > 0 ? (
        <StyleSampleReview
          style={style}
          media={media}
          references={references.filter(m => m.tags.includes('vsm'))}
          archivedSampleSlideshow={archivedSampleSlideshow.sort(
            (a, b) => a.position - b.position
          )}
          mutateReferencesArchive={mutateReferencesArchive}
          mutateReferences={mutateReferences}
          sampleSlideshow={sampleSlideshow.sort((a, b) => a.position - b.position)}
          sampleSlideshowUploading={sampleSlideshowUploading}
          onDropHandler={onDropHandler}
          updatePositionsHandler={updatePositionsHandler}
          archiveView={location.pathname.indexOf('/archive') > 0}
          {...props}
        />
      ) : (
        <StyleSummary
          title={title}
          tooltipText={tooltipText}
          tooltipTitle={tooltipTitle}
          styleInfo={styleInfo}
          statusState={[
            style?.status ?? style?.listing.status ?? WORKFLOW_STATUS.IN_PROGRESS.value,
            onSetStatus,
          ]}
          productName={style.name}
          style={style}
          media={media}
          references={references}
          onDropHandler={onDropHandler}
          sampleSlideshowUploading={sampleSlideshowUploading}
          sampleSlideshow={sampleSlideshow
            .sort((a, b) => a.position - b.position)
            .filter(s => s.type !== FOLDER)}
          progressSpecs={progressSpecs}
          {...props}
        />
      )}
    </>
  );
};

export default AtelierSummaryContainer;

import React from 'react';
import moment from 'moment';

import StyleSampleList from 'components/VirtualAtelier/StyleSummary/StyleSampleList';
import {
  WORKFLOW_STATUS,
  classNamesFromStatus,
} from 'components/VirtualAtelier/StyleSummary/util';
import IconFolder from 'assets/icons/Icon-folder.svg';

const headerGroups = [
  {
    header: '',
  },
  {
    header: 'Name',
  },
  {
    header: 'Uploaded Date',
  },
  {
    header: 'Metadata',
  },
];

const AtelierModelUploadsListView = ({ media, editTileOptions, ...props }) => {
  const cellData = React.useMemo(
    () =>
      media?.map(listing => {
        const { status = WORKFLOW_STATUS.IN_PROGRESS.value, type } = listing;

        return {
          key: listing.key,
          col: [
            {
              imageUrl: listing.thumbnail || listing.url,
              icon: type === 'folder' ? IconFolder : undefined,
            },
            {
              cell: listing.name || 'Name',
            },
            {
              cell: moment.unix(listing.created).format('DD MMM ha') || 'Uploaded Date',
            },
          ],
          data: listing,
        };
      }),
    [media]
  );

  return (
    <StyleSampleList
      headerGroups={headerGroups}
      showHeader={false}
      cellData={cellData}
      popUpOptions={editTileOptions}
      updatePositionsHandler={data =>
        console.log('updating asset positions unavailable. Positions will not persist.')
      }
      {...props}
    />
  );
};

export default AtelierModelUploadsListView;

import React, { useMemo, useState } from 'react';
import ImageGallery from 'react-image-gallery';

import 'components/Dashboard/UploadSketches/AdditionalReference.scss';
import 'components/Dashboard/ProductUpload/ProductUpload.scss';
import Asset from 'components/Asset';
import PopUp from 'components/Common/PopUp/PopUp';
import Tile from 'components/Tile/Tile';
import { ASSET_TYPES } from 'constants/assets';
import DotsGray from 'assets/icons/dots-grey.svg';
import { AtelierGalleryImage } from './AtelierModelUploads';
import { IPopUpOptions } from 'models/Dashboard/IPopUpOptions';
import ExpandIcon from 'assets/icons/icon-expand.svg';

export const renderGalleryThumbnail = (
  item: any,
  open: any,
  handleOpen: any,
  renderingThumbnail: boolean,
  setOriginal: (arg: any) => void
) => RenderGalleryAsset(item, open, handleOpen, renderingThumbnail, setOriginal);

export function RenderGalleryAsset(
  item: any,
  open: any = {},
  handleOpen: any,
  renderingThumbnail: boolean,
  setOriginal: (arg: any) => void,
  getAssetLevelHistory?: any
) {
  const {
    asset,
    save,
    localUrl,
    localFile,
    key,
    onUploadHandler,
    type = 'image',
    name = '',
    onClickHandler,
    deleteModelActionHandler,
    renameModelActionHandler,
    onArchiveHandler,
    original,
  } = item;

  const popUpOptions = [
    {
      selectItem: 'Download',
      onClick: () => onClickHandler(item),
      download: true,
    },
    {
      selectItem: 'Delete',
      onClick: () => deleteModelActionHandler(item),
    },
    {
      selectItem: 'Rename',
      onClick: () => renameModelActionHandler(item),
    },
    {
      selectItem: 'Archive',
      onClick: () => onArchiveHandler(item),
    },
    {
      selectItem: 'History',
      onClick: () => getAssetLevelHistory(item),
    },
  ] as IPopUpOptions[];

  const typeSplit = type?.split('/');
  const nameSplit = name?.split('.');
  const extension =
    typeSplit.length > 1
      ? typeSplit[1]
      : nameSplit.length > 1
      ? nameSplit[nameSplit.length - 1]
      : 'file';
  return (
    <>
      <div className={`atelierModelUploads__gallery-thumb`}>
        <div className='thumbnail-header'>
          {!renderingThumbnail && (
            <div className='threedots'>
              <img src={DotsGray} alt='Delete' onClick={() => handleOpen(key)} />
              <div style={{ zIndex: 99999999 }}>
                {open[key] ? (
                  <PopUp open={open[key]}>
                    {popUpOptions?.map(({ selectItem, onClick, download }) =>
                      download ? (
                        <li className='pop-up__li' key={selectItem}>
                          <a
                            target='_blank'
                            rel='noreferrer'
                            href={original}
                            download={name}
                            onClick={() => {
                              handleOpen(key);
                              onClick?.();
                            }}
                          >
                            {selectItem}
                          </a>
                        </li>
                      ) : (
                        <li
                          className='pop-up__li'
                          key={selectItem}
                          onClick={() => {
                            handleOpen(key);
                            onClick?.();
                          }}
                        >
                          {selectItem}
                        </li>
                      )
                    )}
                  </PopUp>
                ) : (
                  <></>
                )}
              </div>
            </div>
          )}
        </div>

        <div className='thumbnail-inner'>
          {asset && localUrl ? (
            <Asset
              asset={asset}
              imageAsFileSeed={localFile}
              saveImages={save}
              key={key}
              onUploadHandler={onUploadHandler}
            >
              <Tile
                {...(type === ASSET_TYPES.image.type && { image: localUrl })}
                {...(type === ASSET_TYPES.video.type && { video: localUrl })}
                {...(type === ASSET_TYPES.model && { model: localUrl })}
                aspectRatio='1-1'
                caption={save ? '  ' : `${type}: ${name}`}
                alt={name}
                objectFit={renderingThumbnail ? 'cover' : 'contain'}
                renderingThumbnail={renderingThumbnail}
                type={type}
                original={original}
                setOriginal={setOriginal}
                item={item}
              />
            </Asset>
          ) : (
            <Tile
              {...(type === ASSET_TYPES.image.type && { image: original })}
              {...(type === ASSET_TYPES.video.type && { video: original })}
              {...(type === ASSET_TYPES.model.type && { model: original })}
              aspectRatio='1-1'
              caption={renderingThumbnail ? ' ' : `${type}: ${name}`}
              alt={name}
              objectFit={renderingThumbnail ? 'cover' : 'contain'}
              renderingThumbnail={renderingThumbnail}
              type={type}
              original={original}
              setOriginal={setOriginal}
              item={item}
            />
          )}
        </div>
      </div>
    </>
  );
}

export interface IAtelierAdditionsPreview {
  images: AtelierGalleryImage[];
  setOriginal: (arg: any) => void;
  getAssetLevelHistory: any;
}

const AtelierAdditionsPreview = (props: IAtelierAdditionsPreview) => {
  const { images, setOriginal, getAssetLevelHistory } = props;
  const [open, setOpen] = useState({});
  const RenderGallery = useMemo(() => RenderGalleryAsset, [
    open,
    setOpen,
    getAssetLevelHistory,
  ]);
  const GalleryThumbnail = useMemo(() => renderGalleryThumbnail, [open, setOpen]);

  const handleOpen = (item: PropertyKey) => {
    //eslint-disable-next-line
    if (open.hasOwnProperty(item)) {
      setOpen({
        ...open,
        [item]: !open[item],
      });
    } else {
      setOpen({
        ...open,
        [item]: true,
      });
    }
  };
  return (
    <ImageGallery
      showThumbnails={true}
      renderItem={(item: any) =>
        RenderGallery(item, open, handleOpen, false, setOriginal, getAssetLevelHistory)
      }
      renderThumbInner={(item: any) =>
        GalleryThumbnail(item, open, handleOpen, true, setOriginal)
      }
      items={images}
      showPlayButton={false}
      showFullscreenButton={false}
      thumbnailPosition={'left'}
      showNav={false}
    />
  );
};

export default AtelierAdditionsPreview;
